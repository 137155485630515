import { i18n, CgCrudFilterSet } from '@cargill/shared'
import _ from 'lodash'

import { FieldType } from '@brain/core'
import { getFieldOptionsFunc, getFieldOptions } from '../api/baseApi'
import { helpers } from '../helpers'
import { useModuleOpen } from '../stores/moduleStore'
import moment from 'moment'
import Vue from 'vue'

const cgCrudFilterSetFieldTypes = new Set([
  FieldType.SELECT,
  FieldType.MULTISELECT,
  FieldType.CHECKBOX,
  FieldType.TOGGLE,
  FieldType.AUTOCOMPLETE
])

const defaultFilterParams = {
  buttons: ['reset', 'apply'],
  suppressAndOrCondition: true
}

const createFilterParams = (params) => {
  return {
    ...defaultFilterParams,
    ...(params ?? {})
  }
}

const isSelectFilter = (field) => {
  return (
    field.filter == null &&
    field.filterFramework == null &&
    isSelectFilterType(field)
  )
}

const isSelectFilterType = (field) => {
  if (field.notSelectFilter) {
    return false
  }
  return (
    (field.type != null && cgCrudFilterSetFieldTypes.has(field.type)) ||
    field.textAsSelectFilter ||
    field.type == 'text'
  )
}

const setFieldFilterFramework = (field, gridMode) => {
  if (isSelectFilter(field)) {
    field.filter = gridMode === 'clientSide' ? 'agSetColumnFilter' : undefined
    field.filterFramework =
      gridMode === 'serverSide' ? CgCrudFilterSet : undefined
    const filterOptions = field.filterOptions || field.options
    field.filterParams = filterOptions
      ? createFilterParams({
          ...field.filterParams,
          values: gridMode === 'serverSide' ? filterOptions : undefined,
          valueFormatter: field.valueFormatter
        })
      : undefined
  }

  if (field.type === FieldType.AUTOCOMPLETE) {
    field.cellRendererParams = { objectLabel: 'label' }
  }
}

const setCheckboxFormatter = (field) => {
  if (field.type == FieldType.CHECKBOX) {
    field.valueFormatter = (params) => {
      if (params.value == null) {
        return null
      }
      return i18n.t(`application.general.${params.value}`)
    }
  }
}

const mapMinValidation = (field) => {
  if (field.min) {
    if (field.validations == null) {
      field.validations = {}
    }
    field.validations.min = field.min
  }
}

const processSelectField = async (metadata, field) => {
  if (
    field.type === FieldType.SELECT ||
    field.type === FieldType.AUTOCOMPLETE
  ) {
    await defaultConfigSelectField(metadata, field)
  }
}

const defineUniqueNonEditableDefault = (field) => {
  if (field.unique != null && field.editable == null) {
    field.editable = false
  }
}

const getFields = async (metadata, gridMode = 'serverSide') => {
  for (const field of metadata.fields) {
    mapMinValidation(field)
    await processSelectField(metadata, field)
    setCheckboxFormatter(field)
    defineUniqueNonEditableDefault(field)
    setFieldFilterFramework(field, gridMode)
  }
  return metadata.fields
}

const defaultConfigSelectField = async (metadata, field) => {
  if (
    !_.isEmpty(field.optionsSource) &&
    _.isEmpty(field.options) &&
    !_.isArray(field.options) &&
    !_.isFunction(field.options)
  ) {
    field.options =
      field.lazyOptions ?? metadata.lazyOptions
        ? getFieldOptionsFunc(field.optionsSource)
        : await getFieldOptions(field.optionsSource)
  } else if (isEnumField(field)) {
    field.options = helpers.createEnumOptions(
      field,
      field.options,
      getEnumI18NLabel
    )
    field.valueFormatter = (param) => {
      const possibleKeys = [param.value, _.camelCase(param.value)].map(
        (x) => `application.enums.${field.enumType}.${x}`
      )
      return tryTranslate(possibleKeys, param.value)
    }
    field.returnObject = false
  } else if (isTranslateFormatter(field)) {
    field.valueFormatter = (param) => {
      const formatter = field.useTranslationFormatter
      const translateKey = `${formatter.translationKey}.${uncapitalize(
        param.value[formatter.translateBy]
      )}`

      const result = i18n.te(translateKey)
        ? i18n.t(translateKey)
        : param.value.text
      return result
    }
  } else if (field.i18n) {
    field.valueFormatter = (param) => {
      const translateKey = `${metadata.translatePrefix}.${metadata.id}.${
        field.id
      }Options.${_.camelCase(param.value)}`
      const result = i18n.te(translateKey) ? i18n.t(translateKey) : param.value
      return result
    }
  } else if (field.entityi18n) {
    field.valueFormatter = (param) => {
      const { getTranslatePrefix } = useModuleOpen()
      const prefix = getTranslatePrefix()
      const value = _.camelCase(param.value)
      const keys = [value]
      if (value.toLowerCase().endsWith('dto')) {
        keys.push(value.substring(0, value.length - 3))
      }
      const possibleKeys = keys.map((x) => `${prefix}.${x}.title`)
      return tryTranslate(possibleKeys, param.value)
    }
  } else if (field.valueFormatter == null) {
    field.valueFormatter = (params) => params?.value?.text ?? params?.value
  }
}

const tryTranslate = (possibleKeys, value) => {
  for (var translateKey of possibleKeys) {
    if (i18n.te(translateKey)) {
      return i18n.t(translateKey)
    }
  }
  return value
}

const getEnumI18NLabel = (field, value) => {
  return i18n.t(`application.enums.${field.enumType}.${value}`)
}

const isEnumField = (field) => {
  return field.options && field.enumType
}

const uncapitalize = (s) => {
  return s && s[0].toLowerCase() + s.slice(1)
}

const isTranslateFormatter = (field) => {
  return field.useTranslationFormatter
}

const enumFields = (metadata) => {
  return (metadata?.fields ?? []).filter(isEnumField)
}

const isSelectNotEnum = (field) => {
  return field.options && field.type == FieldType.SELECT && !field.enumType
}

const selectNotEnumFields = (metadata) => {
  return (metadata?.fields ?? []).filter(isSelectNotEnum)
}

const checkData = (data) => {
  data = data ?? []
  return Array.isArray(data) ? data : [data]
}

const mapFieldsTarget = (metadata) => {
  metadata.fields.forEach((field) => {
    field.target = mapTarget(field.target)
  })
}

const mapTarget = (target) => {
  if (target == null) {
    return null
  }
  return target.map((target) => {
    if (target == 'table') {
      return 'list'
    } else if (target == 'form-edit' || target == 'formEdit') {
      return 'form'
    }
    return target
  })
}

const buildMetadata = async (metadata, service) => {
  if (service) {
    setupLazyLoadGridFilters(metadata, service)
    for (const detail of metadata.details ?? []) {
      if (detail?.detailsObject?.service) {
        setupLazyLoadGridFilters(detail, detail.detailsObject.service)
      }
    }
  }
  if (!metadata.configurations) {
    metadata.configurations = {}
  }
  metadata.configurations.isObjectType = (field) =>
    field.propertyType === 'idLabelPair' ||
    field.propertyType === 'idOptionLabelPair'
  mapFieldsTarget(metadata)
  metadata.fields.forEach((field) => {
    field.type = field.type.toLocaleLowerCase()
  })

  metadata.fields = await getFields(metadata)
  await Promise.all((metadata.details ?? []).map((meta) => buildMetadata(meta)))
  return metadata
}

const processServices = (metadata, service, detailsObject) => {
  processService(service, metadata)
  const details = metadata.details ?? []
  details.forEach((detail) =>
    processService(detailsObject[detail.name].service, detail)
  )
}

const processService = (service, metadata) => {
  service.getPostProcessing = (data) => getPostProcessing(data, metadata)
  service.createUpdatePreProcessing = (data) =>
    createUpdatePreProcessing(data, metadata)
}

const getPostProcessing = async (data, metadata) => {
  data = checkData(data)
  // TODO: Melhorar tratamento de ENUM pra usar valueFormatterForm e não pré-processamento/pós processamento
  const getValueEnum = (field, item) => item[field.id]
  const getValueSelect = (field, item) => item[field.id]?.value

  const processSelect = async (fields, itens, getValue) => {
    const allOptions = await Promise.all(
      fields.map((field) => getOptions(field, {}))
    )
    var index = 0
    for (const field of fields) {
      const options = allOptions[index++]
      field.allOptions = options
      for (const item of itens.filter((item) => getValue(field, item))) {
        const option = options.find((x) => x.value == getValue(field, item))
        if (option == null && typeof item[field.id] === 'object') {
          item[field.id] = {
            label: item[field.id].label,
            text: item[field.id].label,
            value: item[field.id].value,
            toString() {
              return this.text
            }
          }
        } else {
          item[field.id] = option
        }
      }
    }
  }

  await processSelect(enumFields(metadata), data, getValueEnum)
  await processSelect(selectNotEnumFields(metadata), data, getValueSelect)
}

const getOptions = async (field, item) => {
  if (field.allOptions) {
    return field.allOptions
  }
  return _.isFunction(field.options)
    ? await field.options({ itemData: item })
    : field.options
}

const createUpdatePreProcessing = (data, metadata) => {
  data = checkData(data)
  // TODO: Melhorar tratamento de ENUM pra usar valueFormatterForm e não pré-processamento/pós processamento
  enumFields(metadata).forEach((field) => {
    data
      .filter((item) => item[field.id] && typeof item[field.id] == 'object')
      .forEach((item) => {
        item[field.id] = item[field.id]?.value
      })
  })
}

const setupLazyLoadGridFilters = (metadata, service) => {
  metadata.fields
    .filter((field) => field.filterOptions == null && isSelectFilterType(field))
    .forEach((field) => {
      field.masterName = metadata.masterName
      field.filterOptions = async (params) => {
        return await service.getFieldValues(field.key, params.filterQuery)
      }
    })
}

const defineLevel = (meta, mainKeysLevel) => {
  let nodeLevel = -1
  if (meta == null || _.isEmpty(meta)) {
    return nodeLevel
  }
  const fields = Array.from(meta.fields, (x) => x.key)

  for (const _key of fields) {
    const findLevel = Object.keys(mainKeysLevel).findIndex(
      (key) => key === _key
    )
    if (findLevel != -1) {
      nodeLevel = _key
    }
  }

  return nodeLevel
}

const defineCellRendererPremiumDiscount = (
  columns,
  TakeUpPremiumDiscountManagementActionsCellRenderer
) => {
  for (const column of columns) {
    if (column.cellRenderer == 'premiumDiscountActions') {
      delete column.cellRenderer
      column.suppressMenu = true
      column.suppressSorting = true
      column.sorteable = false
      column.pinned = 'right'
      column.cellRendererFramework = Vue.extend(
        TakeUpPremiumDiscountManagementActionsCellRenderer
      )
    }
  }
}

const defineCellRendererStuffingPortal = (
  columns,
  RadioCellRenderer,
  StuffingPortalActionsCellRenderer,
  StuffingPortalStatusCellRenderer
) => {
  for (const column of columns) {
    if (column.cellRenderer == 'agGroupCellRenderer') {
      if (column.fieldType == FieldType.DATE) {
        column.valueFormatter = (params) =>
          moment(params.value).format(i18n.t('application.dateFormat'))
      }

      if (column.fieldType == FieldType.DATETIME) {
        column.valueFormatter = (params) =>
          moment(params.value).format(i18n.t('application.dateTimeFullFormat'))
      }
    } else if (column.cellRenderer == 'RadioCellRenderer') {
      delete column.cellRenderer
      column.cellRendererFramework = Vue.extend(RadioCellRenderer)
    } else if (column.cellRenderer == 'StuffingPortalActionsCellRenderer') {
      delete column.cellRenderer
      column.suppressMenu = true
      column.suppressSorting = true
      column.sorteable = false
      column.pinned = 'right'
      column.cellRendererFramework = Vue.extend(
        StuffingPortalActionsCellRenderer
      )
    } else if (column.cellRenderer == 'StuffingPortalStatusCellRenderer') {
      delete column.cellRenderer
      column.cellRendererFramework = Vue.extend(
        StuffingPortalStatusCellRenderer
      )
    }
  }
}

const defineCellRendererCockpit = (
  columns,
  InstructionDetailsTableCellRenderer
) => {
  for (const column of columns) {
    if (
      column.cellRenderer != undefined &&
      column.cellRenderer == 'InstructionDetailsTableCellRenderer'
    ) {
      delete column.cellRenderer
      column.cellRendererFramework = Vue.extend(
        InstructionDetailsTableCellRenderer
      )
    }
  }
}

const removePermissions = (meta, permissionsToRemove) => {
  const permissionsToRemoveSet = new Set(permissionsToRemove)
  meta.permissions = meta.permissions.filter(
    (p) => !permissionsToRemoveSet.has(p)
  )
}

export default {
  enumFields,
  isSelectNotEnum,
  selectNotEnumFields,
  checkData,
  buildMetadata,
  processServices,
  setupLazyLoadGridFilters,
  getPostProcessing,
  defineLevel,
  defineCellRendererStuffingPortal,
  defineCellRendererPremiumDiscount,
  defineCellRendererCockpit,
  removePermissions
}
